import React, { useEffect } from 'react'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import { Helmet } from 'react-helmet'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import Banner from 'components/banner'
import Block from 'components/block'
import Image from 'components/image'
import Meta from 'components/meta'
import ProjectGrid from 'components/project-grid'
import Section from 'components/section'
import Wrapper from 'components/wrapper'

import { useImageFiles } from 'utils/use-image-files'

interface Props {
  location: Location
}

const Index = ({ location }: Props) => {
  useEffect(() => {
    window.EBWidgets.createWidget({
      widgetType: 'checkout',
      eventId: '301609400837',
      modal: true,
      modalTriggerElementId: 'eventbrite-widget-modal-trigger-301609400837',
    })
  })

  const imageNode = (fileName) => {
    return useImageFiles().nodes.find((n) => n.relativePath == fileName)
  }
  return (
    <Wrapper location={location} className="w-100 mx-auto d-flex flex-column">
      <Meta />
      <Helmet>
        <script src="https://www.eventbrite.com/static/widgets/eb_widgets.js"></script>
      </Helmet>
      <Banner backgroundImage="adobe-355966925-banner_2560x320.jpg">
        <div
          className="d-none d-lg-flex flex-column justify-content-evenly align-items-center"
          style={{ minHeight: '240px' }}
        >
          <GatsbyImage
            backgroundColor="transparent"
            image={getImage(imageNode('nabla-wm-2line_315x150.png'))}
            alt="Nabla Festival logo"
            loading="eager"
          />
          <div className="banner-heading">Contemporary music, dance & intermedia</div>
        </div>
        <div
          className="p-3 d-none d-sm-flex d-lg-none d-flex flex-column justify-content-evenly align-items-center"
          aria-hidden="true"
        >
          <GatsbyImage
            backgroundColor="transparent"
            image={getImage(imageNode('nabla-wm-2line_210x100.png'))}
            alt="Nabla Festival logo"
            loading="eager"
          />
        </div>
        <div
          className="p-3 d-sm-none d-flex flex-column justify-content-evenly align-items-center"
          aria-hidden="true"
        >
          <GatsbyImage
            backgroundColor="transparent"
            image={getImage(imageNode('nabla-wm-2line_105x50.png'))}
            alt="Nabla Festival logo"
            loading="eager"
          />
        </div>
      </Banner>
      <Section
        backgroundStyle="body-darker"
        backgroundImage="adobe-355966925-bg-center_1920x1280.jpg"
      >
        <a className="anchor" id="festival" />
        <Row className="py-3 justify-content-center text-center">
          <Col xs={12}>
            <GatsbyImage
              backgroundColor="transparent"
              image={withArtDirection(getImage(imageNode('nabla-nyc-poster_1920x1080.png')), [
                {
                  media: '(max-width: 1280px)',
                  image: getImage(imageNode('nabla-nyc-poster-crop_1280x720.png')),
                },
              ])}
              alt="Nabla Festival Poster"
              loading="eager"
            />
            <div className="w-100 bg-white bg-opacity-75">
              <Button
                style={{ fontSize: '3vw' }}
                className="text-center p-1 p-md-2 my-1 my-md-3 border border-dark border-2"
                variant="outline-dark"
                id="eventbrite-widget-modal-trigger-301609400837"
              >
                Get Tickets
              </Button>
            </div>
          </Col>
        </Row>
      </Section>
      <Section
        backgroundStyle="body-darker"
        backgroundImage="adobe-355966925-bg-right_1920x1280.jpg"
      >
        <Row className="py-3 justify-content-center text-center">
          <Col xs={12}>
            <GatsbyImage
              backgroundColor="transparent"
              image={withArtDirection(getImage(imageNode('liminal-space-poster-1_1920x1080.png')), [
                {
                  media: '(max-width: 1280px)',
                  image: getImage(imageNode('liminal-space-poster-crop-1_1280x720.png')),
                },
              ])}
              alt="Poster for Incanti (Spells)"
              loading="eager"
            />
          </Col>
        </Row>
      </Section>
      <Section
        backgroundStyle="body-darker"
        backgroundImage="adobe-355966925-bg-left_1920x1280.jpg"
      >
        <Row className="py-3 justify-content-center text-center">
          <Col xs={12}>
            <GatsbyImage
              backgroundColor="transparent"
              image={withArtDirection(getImage(imageNode('liminal-space-poster-2_1920x1080.png')), [
                {
                  media: '(max-width: 1280px)',
                  image: getImage(imageNode('liminal-space-poster-crop-2_1280x720.png')),
                },
              ])}
              alt="Poster for In the Liminal Space"
              loading="eager"
            />
          </Col>
        </Row>
      </Section>
    </Wrapper>
  )
}

export default Index
